//набір символів "іконок" або "емодзі"
//https://emojigraph.org/uk/people-body/
//https://ru.piliapp.com/symbol/star/
//https://ru.piliapp.com/emoji/list/
//https://emojidb.org/refresh-emojis
//https://pr-cy.ru/emoji/
//https://emojiguide.org/ru/%D1%81%D1%82%D1%80%D0%B5%D0%BB%D0%BA%D0%B8


import React from "react"

const Emo = {
    HomeEmo : () => {return (<span aria-hidden="true" role="img">🏠</span>)},
    NotesEmo : () => {return (<span aria-hidden="true" role="img">📓</span>)},
    FavoritesEmo : () =>{return (<span aria-hidden="true" role="img">🌟</span>)},
    Add : () => {return (<span aria-hidden="true" role="img">➕</span>)},
    Plus : () => {return (<span aria-hidden="true" role="img">➕</span>)},
    Minus : () => {return (<span aria-hidden="true" role="img">➖</span>)},
    User1 : () => {return (<span aria-hidden="true" role="img">🧑</span>)},
    Like : () => {return (<span aria-hidden="true" role="img">👍</span>)},
    DisLike : () => {return (<span aria-hidden="true" role="img">👎</span>)},
    User2 : () => {return (<span aria-hidden="true" role="img">🧍</span>)},
    Aliluya : () => {return (<span aria-hidden="true" role="img">🙏</span>)},
    Attantion : () => {return (<span aria-hidden="true" role="img">☝️</span>)},
    HendUp : () => {return (<span aria-hidden="true" role="img">🙋</span>)},
    Star1 : () => {return (<span aria-hidden="true" role="img">☆</span>)},
    Star2 : () => {return (<span aria-hidden="true" role="img">★</span>)},
    Map1 : () => {return (<span aria-hidden="true" role="img">🌍</span>)},
    Look1 : () => {return (<span aria-hidden="true" role="img">🔎</span>)},
    Ok1 : () => {return (<span aria-hidden="true" role="img">✔️</span>)},
    Ok2 : () => {return (<span aria-hidden="true" role="img">🆗</span>)},
    Cancel1 : () => {return (<span aria-hidden="true" role="img">❌</span>)},
    Edit1 : () => {return (<span aria-hidden="true" role="img">✍️</span>)},
    Edit2 : () => {return (<span aria-hidden="true" role="img">✎</span>)},
    Empty1 : () => {return (<span aria-hidden="true" role="img">🔲</span>)},
    Pin1 : () => {return (<span aria-hidden="true" role="img">📌</span>)},
    ArrowDown1 : () => {return (<span aria-hidden="true" role="img">⬇</span>)}, //{' \u2193\u2191'} - ⬇⬆ - так простіще
    Back1 : () => {return (<span aria-hidden="true" role="img">↩️</span>)},
    Back2 : () => {return (<span aria-hidden="true" role="img">⏪</span>)},
    Refresh1 : () => {return (<span aria-hidden="true" role="img">🔄</span>)},
    Avto1 : () => {return (<span aria-hidden="true" role="img">🚙</span>)},
    
    Save1 : () => {return (<span aria-hidden="true" role="img">💾</span>)},
    Passkey : () => {return (<span aria-hidden="true" role="img">🔑</span>)},
    Tools1 : ()  => {return (<span aria-hidden="true" role="img">⚒</span>)},
    Tools2 : ()  => {return (<span aria-hidden="true" role="img">🛠️</span>)},
    Cicle1 : ()  => {return (<span aria-hidden="true" role="img">♻</span>)},
    Cicle2 : ()  => {return (<span aria-hidden="true" role="img">🔃</span>)},
    Stop1 : ()  => {return (<span aria-hidden="true" role="img">🚷</span>)},
    End1 : ()  => {return (<span aria-hidden="true" role="img">🔚</span>)},
    ArrowLeft1 : ()  => {return (<span aria-hidden="true" role="img">⬅️</span>)},
    ArrowDown1 : ()  => {return (<span aria-hidden="true" role="img">⬇️</span>)},
    ArrowUp1 : ()  => {return (<span aria-hidden="true" role="img">⬆️</span>)},
    Exclamation : ()  => {return (<span aria-hidden="true" role="img">❗</span>)},
    DocOpen : () => {return (<span aria-hidden="true" role="img">📑</span>)},
    DocClose : () => {return (<span aria-hidden="true" role="img">🗞️</span>)},

   // 📂    📚📃📝🗓️📈📉📊
   //👌🤏👍👎🧑‍⚕️

    //⛔📵⚠️🚷📄
    //↗️➡️↘️⬇️↙️⬅️↕️↔️↩️↪️⤴️⤵️🔃🔄
    //✖️➕➖➗🟰♾️‼️⁉️❓❔❕❗✅☑️✔️❌❎🔠🔢
    //☺︎☹︎☠︎❄︎✂︎⚒︎⚖︎⬆︎↗︎➡︎↘︎⬇︎↙︎⬅︎↖︎↕︎↔︎↩︎↪︎⤴︎⤵︎▶︎⏭︎⏯︎◀︎⏮︎⏸︎☑︎✔︎◻︎✖︎‼︎⁉︎♻︎☑︎✔︎
    //💾🖨️💿🔍🔎🔑🗝️🔨⚒️🛠️
    //✉️📩📤📥📝📈✂️⚙️👤🗣️👥
    
    
    //🌐 🌍 🗝  ⚙
    //    ↩    🔄        ✔️    ❌    📌    ❔    ⬅⬇⬆➡➜✔✖❕
    //⤵️↘️
    //🛠️↪️
    //https://emojidb.org/refresh-emojis
    //❓   
    //⁉️ 💪🏿🔓💬🚗
    //🔁
    //🤷</span>)}, 
    //😂</span>)},
    //🔍
    //🔎
    //💊
    //🖍️↩
    
    //📚
    //📊
    //📈
    //🗸
    //🗹
    //🗴
    //🗵
    ///⏪◀️▶️⏩➡️⬅️⬆️⬇️↗️↘️↙️↖️↕️↔️↪️↩️⤴️⤵️
    //🌍⚙️📒📕📚
    //📸📞⏱⏳💰🛠🔑📃📊📆📇📂📚📒📖📝🔍🔏🌍🏠🏥🔒🚀🚎🚗
    //⬛⬜🔲
    
}
export default Emo

