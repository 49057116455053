/*
коротко роботи з типами 3 - візит в аптеку, 4 - візит до лікаря
*/

import { Button,Card,CardBody,CardHeader,CardText,CardSubtitle } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { RoutPath } from "../../WTConst";
import Emo from "../../comcom/Emo";
import {dateTimeStrToStr} from "../../comcom/ComFunc"
import WortType from "./WorkType";
//import DoctorVizit from "../../pages/DoctorVizit";

const Work34 =  (props) => {

    const navigate = useNavigate()
    let MP = props.MP
    let IDWORK = 0
    let IDSOTR = 0
    let IDORG = 0
    let IDORGSOTR = 0
    let DTVIZIT = ''
    let idjob =  0 
    let idjobtp = 0
    let jobname = ''
    let txt = ''
    let dtfix = ''
    let datevizit = ''
    let latitude = props.latitude
    let longitude = props.longitude
    let vr = ''
    let vrspec = ''
    let org = ''
    let adr = '' 

    let canEdit = true;
   
    
    if(props.job){
        IDWORK = props.job.IDWORK
        IDSOTR = props.job.IDSOTR
        IDORG = props.job.IDORG
        IDORGSOTR = props.job.IDORGSOTR
        DTVIZIT = props.job.DTVIZIT
    
        jobname = props.job.JOBNAME
        idjob = props.job.IDJOB ? props.job.IDJOB : 0 
        adr = props.job.ADR
        org = props.job.ORGNAME
        vr =  props.job.VR
        vrspec = props.job.VRACHSPEC
        idjobtp = props.job.IDJOBTP
        txt = props.job.TXT
        dtfix = props.job.DTFIX
        datevizit = dateTimeStrToStr( props.job.DATEVIZIT )
        if(!datevizit) datevizit = props.job.DATEVIZIT 
        if(idjob>0 && props.job.GEOX){
            latitude = props.job.GEOX
            longitude = props.job.GEOY
        }
    }
    const ifdone = idjob > 0 
  
    const EditJob = () => {
        if(idjobtp == 3){
            const params ={
                IDWORK : IDWORK,
                IDORG:IDORG,IDSOTR:IDSOTR,DTVIZIT:DTVIZIT,
                MP : MP,VR: vr + ' '+vrspec,
                ORG : org, ADR : adr}
            navigate(RoutPath.PharmVizit,{state:params})
        }

        if(idjobtp == 4){
            const params ={
                IDWORK : IDWORK,
                IDORG:IDORG,IDSOTR:IDSOTR,IDORGSOTR:IDORGSOTR,DTVIZIT:DTVIZIT,
                MP : MP,VR: vr + ' '+vrspec,
                ORG : org, ADR : adr}
            navigate(RoutPath.DoctorVizit1,{state:params})
        }
        if(idjobtp == 8){
            const params ={
                IDWORK : IDWORK,
                IDORG:IDORG,IDSOTR:IDSOTR,IDORGSOTR:IDORGSOTR,DTVIZIT:DTVIZIT,
                MP : MP,VR: vr + ' '+vrspec,
                ORG : org, ADR : adr}
            navigate(RoutPath.PageKrStil,{state:params})
        }
    }
//<div style={{margin:"10px 0px 0px 0px"}}>
//</div>

    return (
        
         <Card  color="primary"  outline  >
            <CardHeader  onClick={EditJob} style={{cursor: "pointer"}}>      
                <WortType OK={ifdone} TP={jobname} DT={datevizit} />           
            </CardHeader>
            <CardText style={{ margin:"0px 1px 0px 40px"}}>
                <span>{vr} {vrspec}  <u>{org}</u> {' '}
                {adr} </span>
            </CardText>
        </Card>
        
    )
}


export default Work34