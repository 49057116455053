//
//загальні кількості клієнтів по МП
//

import React from "react";

import Table1AndFilter from "../../comcom/Table1AndFilter";
import { usePageData1 } from "../../comcom/PageData1";
import Eng001 from "../../engine/eng001";

const CliBazaMP = (props) => {

    const loadData = async (filterData) =>{
        if(!filterData.MP &&  !filterData.DISTR ){
            //alert('Задайте параметри пошуку...')
            return false
        }        
        try{
            let d = await Eng001.loadMP_CliAll(filterData)
            return d
        }catch(err){
            return false
        }
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)

    const fieldsFilterForm = [
        {fname:'DISTR',ftype :'text',fcaption:'Регіон',fnotNull:false,fval:''},
        {fname:'MP',ftype :'text',fcaption:'МП',fnotNull:false,fval:''},
        {fname:'DET',ftype :'select',fcaption:'Детально',fval:'N',fvals:['Y','N']},
        ]
    const Theaders = ['МП','Обл','Тип','Загалом','Активні','Додаткові']
    const Tfields = ['NAME1','OBL','T','KOL','KOL_R','KOL1_DOD']
    

    return <>
    <Table1AndFilter 
        formHeader={'База МП'} 
        fieldsFilterForm = {fieldsFilterForm}
        loadDataFromServer = {fLoadData}
        Theaders = {Theaders} 
        Tfields = {Tfields} 
        filterOpt="1" 
        data = {data}
        loading = {loading}
        err ={err}
    />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </>



}

export default CliBazaMP