import React from "react";
import { Accordion,AccordionHeader,AccordionBody,AccordionItem, Badge, Button } from "reactstrap";


import appData from "../AppData";
import RedirectToLogin from '../components/RedirectToLogin'

import  {usePageData1}  from "../comcom/PageData1";
import Eng001 from "../engine/eng001";
import { dateStrWEB,dateTimeStrToStr1 } from "../comcom/ComFunc";
import UsgPrev from "../components/MainForm/UsgPrev";
import Wait1 from "../comcom/Wait1";
import NewsPrev from "../components/MainForm/NewsPrev";
import Op from "../components/Com/Op";




const PageMainForm = props =>{
    const [open, setOpen] = React.useState('0');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };

    const loadData = async() =>{
      const p = {DFROM : dateStrWEB(new Date(),-10),ALARM:1}
      let Uzg = await Eng001.loadDocList(p)
      if(!Uzg) return (false,false,false)
      let News = await Eng001.loadNews({IFWORK:'Y'})
      if(!News) return (Uzg,false,false)
      let Op = await Eng001.loadOp({'IDOPTP':1})
      if(!Op) return (Uzg,News,false)
      return {Uzg,"News":News["NEWS"],Op}
    }

    const [data,loading,errUzg,fLoadData,setNewData] =  usePageData1(loadData)
    let kolUzg = ''
    if(data) kolUzg = `${data['Uzg'].length}`
    let kolNews = ''
    if(data['News']) kolNews = `${data['News'].length}`
    let kolOp = ''
    if(data['Op']) kolOp = `${data['Op'].length}`


    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )    
    //{data && data['Op'].length==0 && <><br /><i>Опитування відсутні.</i></>}            
      try{
          return <>
            <Button color="primary" outline onClick={fLoadData}>Оновити</Button>

            {data['News'] && data['News'].map((m,i) =>{if(m['SHOWTOP']=='Y')return <NewsPrev d={m} />})
            }


            <Accordion open={open} toggle={toggle}>

            {data && data['Op'].length!=0 &&
              <AccordionItem>
              
                <AccordionHeader targetId="3">Опитування
                      {'  '}<Badge  color="info">{kolOp}</Badge>
                 </AccordionHeader>
                <AccordionBody accordionId="3">                  
                  {loading && <Wait1 />}
                  
                  {
                    data['Op'].map((m,i) => <Op OP={m} />)
                  }
                </AccordionBody>
              </AccordionItem>
              }
              <AccordionItem>
                <AccordionHeader targetId="2">Узгодження "до виконання" 
                      {'  '}<Badge  color="info">{kolUzg}</Badge>
                 </AccordionHeader>
                <AccordionBody accordionId="2">                  
                  {loading && <Wait1 />}
                  {data && data['Uzg'].length==0 && <><br /><i>Документи відсутні.</i></>}
                  {data && 
                    data['Uzg'].map((m,i) => <UsgPrev d={m} delDoRozgladu={fLoadData}/>)
                  }
                  
                </AccordionBody>
              </AccordionItem>

              <AccordionItem>
                <AccordionHeader targetId="1"> Новини, об`яви
                      {'  '}<Badge  color="info">{kolNews}</Badge>
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  
                  
                  {loading && <Wait1 />}
                  {data['News'] && data['News'].length==0 && <><br /><i>Новини відсутні.</i></>}
                  {data['News'] && 
                    data['News'].map((m,i) =><NewsPrev d={m} />)
                  }                  
                </AccordionBody>
              </AccordionItem>

      </Accordion>      
      </>
      }catch(Err){
        return <>{Err}</>
      }
}
//

export default PageMainForm