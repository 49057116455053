import React from "react";
import { Input,Button,
        Modal,ModalHeader,ModalBody,ModalFooter,
        Table
 } from "reactstrap"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { toInteger,FormatTXT } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import { CachingTag } from "../../WTConst";
import usePageDataCashedWT1 from "../PageDataCashedWT1"

export const SelectUser = (props) => {
    let btnCapt = props.CAPT ? props.CAPT : '[...]'
    let captH = props.CAPTH ? props.CAPTH : ''
    let FUN = props.FUN
    let IDFILTER = props.IDFILTER ? props.IDFILTER : ''
    const [ifSelect,setSelect] = React.useState(false)
    const [filterStr,setFilterStr] = React.useState(false)
    const loadData = async ()=>{
        let d = await Eng001.loadSpivr()
        return d
    }
    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.PageSpivr,loadData)

    const selectFunction = async (e)=>{
        let tg
        tg = e.target.parentNode.parentNode
        let id = sData[tg.rowIndex]["IDSOTR"]
        if(FUN) await FUN(id)
    }
    const toggleSelect = ()=>{setSelect(!ifSelect)}
    const onSearch = (e)=>{ 
        setFilterStr(e.target.value) 
    }
    let sData = data
    if(IDFILTER){
        sData = []
        for(let i=0;i<data.length;i++){
            if(IDFILTER.includes(','+data[i]['IDSOTR']+',')){
                sData.push(data[i])
            }
        }
    }
    data = sData
    if (filterStr) {
        sData = []
        const needle = filterStr.toLowerCase();
        let ifOk = false
        for(let i=0;i<data.length;i++){
            //ifOk = false
            //for (let k in data[0]) {     
            //   if (data[i][k] && data[i][k].toString().toLowerCase().includes(needle)) {
            //    ifOk = true
            //    }     
            //}
          ifOk = data[i]['NAME1'] && data[i]['NAME1'].toString().toLowerCase().includes(needle)  
          if(ifOk) sData.push(data[i])
        }
      }



    return <>
        {!ifSelect && <Button color="link" onClick={toggleSelect}>{btnCapt}</Button>}
        {data && <>
            <Modal isOpen={ifSelect} toggle={toggleSelect} style={{height: '90%'}} scrollable="True">
                <ModalHeader toggle={toggleSelect}>Співробітники {captH}
                </ModalHeader>
                <ModalBody>
                    <Table className="table table-striped table-hover table-sm">
                        {
                        
                        sData.map((d,fid) =>{
                            return <tr>
                                <td style={{float:"left"}}><Button style={{float:"left"}} color="link" onClick={selectFunction}>{d['NAME1'].substr(0,25)}</Button></td>
                                <td><Button  color="link" onClick={selectFunction}>{d['SOTRTPLONG'].substr(0,8)}</Button></td>
                                <td><Button  color="link" onClick={selectFunction}>{d['OBL']}</Button></td>

                                </tr>
                            }
                        )}                    
                    </Table>
                </ModalBody>
                <ModalFooter>
                <Input type="text" placeholder='Пошук/Фільтр' name="filterStr" id="filterStr"  onChange={onSearch} style={{width:'90%'}}/>    
                </ModalFooter>            
            </Modal>
            </>
        }
    </>
}