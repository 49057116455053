import Engine from "./engine";
import { CachingTag } from "../WTConst"

class Eng001 {
    static async getWorkData(idsotr=0,dateWork=''){
        return await Engine.postData('m001/getdaywork',{idsotr:idsotr,dateWork:dateWork});
    }
    static async seveSimpleWork(idjob,idJobTp,txt,longitude,latitude){
        idjob = idjob ? idjob : 2
        longitude = longitude ? longitude : 0
        latitude = latitude ? latitude : 0
        return await Engine.postData('m001/saveSimpleWork',{idjob,idJobTp,txt,longitude,latitude});
    }
    static async getPharmacy1(param){
        return await Engine.postData('m001/loadPharmacy1',param);
    }
    static async getDoctor1(param){
        const r = await Engine.postData('m001/loadDoctor1',param);
        return r
    }    
    static async getPharmacy2(param){
        return await Engine.postData('m001/loadPharmacy2',param);
    }
    static async loadShemotehnica1(param){
        return await Engine.postData('m001/loadShemotehnica1',param);
    }
    static async loadZvitMeregi(param){
        return await Engine.postData('m001/loadNetRep1',param);
    }
    static async addPharmToVizit(param){
        let r = await Engine.postData('m001/addPharmToVizit',param);
        if(r){
            localStorage.removeItem( CachingTag.DayWorkCash );
            window.alert('Ok')
        }
        return r;
    }
    static async addDoctorToVizit(param){
        let r = await Engine.postData('m001/addDoctorToVizit',param);
        //if(r)
        {
            localStorage.removeItem( CachingTag.DayWorkCash );
            window.alert('Ok')
        }
        return r;
    }
    static async getDoctor2(param){
        return await Engine.postData('m001/loadDoctor2',param);
    }    
    static async loadPromoCli(param){
        return await Engine.postData('m001/loadPromoCli',param);
    }
    static async loadPromoList(){
        return await Engine.postData('m001/loadPromoList',[]);
    }    
    static async editPromoCli(param){
        return await Engine.postData('m001/editPromoCli',param);
    }    
    static async loadPharmacyEmpl(param){
        return await Engine.postData('m001/loadPharmacyEmpl',param);
    } 
    static async loadEmplAttr(param){
        return await Engine.postData('m001/loadEmplAttr',param);
    }   
    static async loadEpmlData(param){
        return await Engine.postData('m001/loadEpmlData',param);
    } 
    static async saveEmpl(param){
        return await Engine.postData('m001/saveEmpl',param)
    }
    static async saveComment(param){
        return await Engine.postData('m001/saveComment',param)
    }    
    static async saveVV1234(param){
        return await Engine.postData('m001/saveVV1234',param)
    }    
    static async vidatKoml(param){
        return await Engine.postData('m001/vidatKoml',param)
    } 
    static async getDoctorVizitData(param){
        return await Engine.postData('m001/getDoctorVizitData',param)
    }     
    static async saveDoctorVizit(param){
        return await Engine.postData('m001/saveDoctorVizit',param)
    }     
    static async loadPharmVizit(param){
        return await Engine.postData('m001/loadPharmVizit',param)
    } 
    static async savePharmVizit(param){
        const data={PARAM : JSON.stringify(param) }
        return await Engine.postData('m001/savePharmVizit',data)
    }     

    static async saveAvto(param){
        const data={AVTO : JSON.stringify(param) }
        return await Engine.postData('m001/saveAvto2',data)
    }  

    static async saveRMWork(param){
        const data={PARAM : JSON.stringify(param) }
        return await Engine.postData('m001/saveRMWork',data)
    }    
    static async loadTask(param){
        return await Engine.postData('m001/loadTask',param)
    } 
    static async loadVizitMP(param){
        return await Engine.postData('m001/loadVizitMP',param)
    }
    static async addRMWork(param){
        return await Engine.postData('m001/addRMWork',param)
    }
    static async loadNetContr(param){
        return await Engine.postData('m001/loadNetContr',param)
    }
    static async loadWork2(param){
        return await Engine.postData('m001/loadWork2',param)
    }
    static async loadInstructions(){
        return await Engine.postData('m001/loadInstructions')
    }
    static async loadHidProd(){
        return await Engine.postData('m001/loadHidProd')
    }
    static async loadTabelDay(){
        return await Engine.postData('m001/loadTabelDay')
    }
    static async saveTabelDay(param){
        return await Engine.postData('m001/saveTabelDay',param)
    }  
    static async loadTableMonth(param){
        return await Engine.postData('m001/loadTableMonth',param)
    }
    static async loadDocList(param){
        return await Engine.postData('m001/loadDocList',param)
    }
    static async loadDoc(param){
        return await Engine.postData('m001/loadDoc',param)
    }    
    static async deleteDocAlarm(param){
        return await Engine.postData('m001/deleteDocAlarm',param)
    }      
    static async AddTXTToDoc(param){
        return await Engine.postData('m001/AddTXTToDoc',param)
    }     
    static async setNewSatusToDoc(param){
        return await Engine.postData('m001/setNewSatusToDoc',param)
    }
    static async loadTPNewDoc(){
        return await Engine.postData('m001/loadTPNewDoc')
    }
    static async createDoc(param){
        return await Engine.postData('m001/createDoc',param)
    }
    static async addUserToDoc(param){
        //IDDOC,IDSOTR_TO_ADD (кого додати),ALARMSTATUS
        return await Engine.postData('m001/addUserToDoc',param)
    }
    
    static async deleteUserFromDoc(param){
        //IDDOC,IDSOTR_TO_DELETE (кого видалити)
        return await Engine.postData('m001/deleteUserFromDoc',param)
    }    
    static async loadSpivr(){
        return await Engine.postData('m001/loadSpivr')
    }   
    static async loadNetTTPoObl(param){
        return await Engine.postData('m001/loadNetTTPoObl',param)
    } 
    static async saveNews(param){
        return await Engine.postData('m001/saveNews',param)
    }     
    static async loadNews(param){
        return await Engine.postData('m001/loadNews',param)
    }     
    static async loadMedicard(param){
        return await Engine.postData('m001/loadMedicard',param)
    }
    static async addMedicard(param){
        return await Engine.postData('m001/addMedicard',param)
    }
    static async delMedicard(param){
        return await Engine.postData('m001/delMedicard',param)
    }
    static async loadCompliment(param){
        return await Engine.postData('m001/loadCompliment',param)
    }
    static async updateCompliment(param){
        return await Engine.postData('m001/updateCompliment',param)
    }
    static async loadAvtoZapr(param){
        return await Engine.postData('m001/loadAvtoZapr',param)
    }
    static async load3plus1(param){
        return await Engine.postData('m001/load_3_plus_1',param)
    }
    
    static async addTo_3_plus_1(param){
        return await Engine.postData('m001/addTo_3_plus_1',param)
    }
    
    static async loadAdrProd(param){
        return await Engine.postData('m001/loadAdrProd',param)
    }
    
    static async loadOp(param){
        return await Engine.postData('m001/loadOp',param)
    }
    static async saveOp(param){
        return await Engine.postData('m001/saveOp',param)
    }   
    static async loadMP_CliAll(param){
        return await Engine.postData('m001/loadMP_CliAll',param)
    }    
    static async loadKR_STOL2(param){
        return await Engine.postData('m001/loadKR_STOL2',param)
    }    
    static async saveKR_STOL2(param){
        return await Engine.postData('m001/saveKR_STOL2',param)
    }

}

export default Eng001

