import React from "react";
import { Button,Alert,Card,CardHeader,CardBody,CardTitle,Collapse } from "reactstrap"

import Form1 from "../../comcom/Form1";
import Emo from "../../comcom/Emo";
import Wait1 from "../../comcom/Wait1";

import Eng001 from "../../engine/eng001";
import { cloneObj } from "../../comcom/ComFunc";

const Op = props =>{
    const [Op,setOp] = React.useState( props.OP)
    if(!Op.DETAIL) Op.DETAIL = []
    const [isOpenOp,setOpenOp] = React.useState(props.OPOPEN)
    const [isWait,setWait] = React.useState(false)

    const togleOpen = () => setOpenOp(!isOpenOp)

    const save = async (r)=>{
        setWait(true)
        let p = r.formData
        p['IDOP'] = Op.IDOP
        p['IDOPTP'] = Op.IDOPTP
        p['IDORG'] = Op.IDORG
        p['IDVIZITD'] = Op.IDVIZITD
        p['IDORGSOTR'] = Op.IDORGSOTR
        await  Eng001.saveOp( p )
        setWait(false)
        let newOp = cloneObj(Op)
        for(let i=0;i<newOp.DETAIL.length;i++){    
            newOp.DETAIL[i]['ANSV'] = r.formData['A_'+newOp.DETAIL[i]['IDOPASK']] 
            newOp.DETAIL[i]['COMMENT'] = r.formData['C_'+newOp.DETAIL[i]['IDOPASK']]
        }
        setOp(newOp)

    }

    //op.IDOP,op.SHOWONE
    //,ask.IDOPASK,ask.ASK,ask.VARANSV
    //,ansv.DD,ansv.ANSV,ansv.COMMENT

    const fields = []
    for(let i=0;i<Op.DETAIL.length;i++){
        let f = {}

            f.fname = 'A_'+Op.DETAIL[i]['IDOPASK']
            f.fcaption = Op.DETAIL[i]['ASK']
            if(Op.DETAIL[i]['FIX']==0){
                f.ftype = 'select'
                f.fvals = Op.DETAIL[i]['VARANSV'].split(';')
                f.fvals.unshift('')
            }else{
                f.ftype = 'info'
            }
            f.fval = Op.DETAIL[i]['ANSV']
            fields.push(f)
            f = {}
            f.fname = 'C_'+Op.DETAIL[i]['IDOPASK']
            f.fcaption = '...'
            f.ftype = 'textNoCapt'
            f.fval = Op.DETAIL[i]['COMMENT']
            fields.push(f)
        
        fields.push({ftype:'line'})
    
    }
    const formCFG = {
        caption : '',
        buttonCaption : "Зберегти",
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : save,
        grid : 2,
    } 



    //{fname:'PWDOLD',ftype :'password',fcaption:'Старий пароль',fhint:'Старий пароль',fnotNull:true,fval:''},
    //{fname:'PWD1',ftype :'password',fcaption:'Новий пароль',fhint:'новий пароль',fnotNull:true,fval:''},
    //{fname:'PWD2',ftype :'password',fcaption:'Новий пароль (2)',fhint:'новий пароль ще раз',fnotNull:true,fval:''},

//warning
    if(isWait) return <Wait1 />    

    return <> 
    <h5>{Op.TXT}{' '}
    <Button color="primary" outline onClick={togleOpen} >{isOpenOp && Emo.ArrowUp1()}{!isOpenOp && Emo.ArrowDown1()}</Button>
    </h5>
    
    <Collapse isOpen={isOpenOp} horizontal>       
    <Alert color="info">             
       <Form1 formCFG={formCFG}  />
       {isWait && <Wait1 />}
    </Alert>
    </Collapse>            

    </>
}

export default Op