//редагування перс даних лікаря або співробітника аптеки
import React from "react";
import { Button } from "reactstrap"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import Form1 from "../../comcom/Form1";
import { toInteger } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001";
import { usePageData1 } from "../../comcom/PageData1"

const EmplEdit1 = (props) =>{
    const IDORG = toInteger(props.IDORG)
    const IDORGSOTR = toInteger(props.IDORGSOTR)
    const FUN = props.FUN
    const IFLIKAR= props.IFLIKAR //0-спиівроб.аптеки 1 - лікар
    const EptyData = {IDORGSOTR:IDORGSOTR,IDORG:IDORG,DOLGN:'',NAME1:'',TEL:'',BIRTHDAY:'',SEX:'',IFWORK:'Y'}

    const [dolgnList,setDolgnList] = React.useState([])
    const [vrachspec,setVrachspec] = React.useState([])
    const [mpList,setMpList] = React.useState([])

    const loadEmplAttr = async () =>{
        if(dolgnList.length===0){
            const d1 = await Eng001.loadEmplAttr({IFLIKAR,IDORG})
            //dolgnList.push(d1.length)
            const dolgn = d1['DOLGN']
            let d2 =[]
            for(let i=0;i<dolgn.length;i++){ 
                if(IFLIKAR && dolgn[i]['VR']==1)  d2.push(dolgn[i]['DOLGN']) 
                if(!IFLIKAR && dolgn[i]['SOTRTT']==1)  d2.push(dolgn[i]['DOLGN']) 
            }
            setDolgnList(d2)
            const vrspec = d1['VRACHSPEC']
            d2 = []
            for(let i=0;i<vrspec.length;i++){ 
                d2.push(vrspec[i]['VRACHSPEC'])
            }
            setVrachspec(d2)
            const mplist = d1['MPLIST']
            d2 = []
            for(let i=0;i<mplist.length;i++){ 
                d2.push(mplist[i]['MP'])
            }
            setMpList(d2)

            
        }
    }
    const loadData = async ()=>{
        await loadEmplAttr()
        if(IDORG>0 && IDORGSOTR===0){
            return EptyData
        }else{
            let d1 = await Eng001.loadEpmlData({IDORG : IDORG, IDORGSOTR : IDORGSOTR})
            if(d1[0].SEX === 'Ms') d1[0].SEX = 'Ж'
            return d1[0]
        }

    }
    const [data,loading,err,fLoadData,setNewData,setloading] =  usePageData1(loadData)

    const saveData = async (newD) =>{
        newD = newD.formData
        newD.IDORG = IDORG
        newD.IDORGSOTR = IDORGSOTR
        newD.IFLIKAR = IFLIKAR
        setloading(true)
        let r = await Eng001.saveEmpl(newD)
        if (r > 0) FUN() 
    }

    const fields = [
        {fname:'NAME1',ftype :'text',fcaption:'ПІБ',fhint:'ПІБ',fnotNull:true,fval:data.NAME1},
        {fname:'DOLGN',ftype :'select',fcaption:'Посада',fhint:'посада',fnotNull:true,fval:data.DOLGN,fvals:dolgnList},
    ]
    if(IFLIKAR){
        fields.push({fname:'VRACHSPEC',ftype :'select',fcaption:'Спеціальність',fhint:'',fnotNull:true,fval:data.VRACHSPEC,fvals:vrachspec})    
        fields.push({fname:'ABC',ftype :'select',fcaption:'ABC',fhint:'',fnotNull:true,fval:data.ABC,fvals:['','A','B','C']})    
        //setMpList(mpList.push(data.MP))
        fields.push({fname:'MP',ftype :'select',fcaption:'МП',fhint:'',fnotNull:true,fval:data.MP,fvals:mpList})    
        fields.push({fname:'TPZAKR',ftype :'select',fcaption:'Тип закр',fhint:'',fnotNull:true,fval:data.TPZAKR,fvals:['','роб','заг']})    
    }
    fields.push({fname:'LL',ftype :'select',fcaption:'Лояльність',fhint:'',fnotNull:true,fval:data.LL,fvals:['',1,2,3]})
    fields.push({fname:'TEL',ftype :'text',fcaption:'Телефон',fhint:'тел',fnotNull:false,fval:data.TELPERS})
    fields.push({fname:'EMAIL',ftype :'email',fcaption:'E-mail',fhint:'ел.адреса',fnotNull:false,fval:data.EMAILPERS})
    fields.push({fname:'IFWORK',ftype :'select',fcaption:'Працює',fhint:'працює',fnotNull:false,fval:data.IFWORK,fvals:['Y','N']})
    fields.push({fname:'SEX',ftype :'select',fcaption:'Стать',fhint:'стать',fnotNull:false,fval:data.SEX,fvals:['Ч','Ж','']})
    fields.push({fname:'BIRTHDAY',ftype :'date',fcaption:'День народження',fhint:'',fnotNull:true,fval:data.BIRTHDAY})
     
    const formCFG = {
        buttonCaption : "Зберегти",
        //cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : saveData,
        //actionCancel : togglePWD,
        //clearData : true,
        //localStorageTag : '',
    }   
    
    

    return <>
        
        {loading && <Wait1 />}
        {err && <Error1 />}
        {data && <div>
            <Form1 formCFG={formCFG}  />
        </div>}
        Edit Empl {IDORG} {IDORGSOTR}
    </>
}


export default EmplEdit1