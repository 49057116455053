//Співробітники аптек
import React from "react"
import { Button,Collapse, CardBody, Card,CardHeader
    ,Modal, ModalHeader, ModalBody } from "reactstrap"
import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { toInteger } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import Table1 from "../../comcom/Table1"
import Emo from "../../comcom/Emo"
import EmplEdit1 from "../Empl/EmplEdit1"
import { usePageData1 } from "../../comcom/PageData1"
import appData from "../../AppData"


const PharmacyEmpl = (props) =>{
    const IDORG = toInteger(props.IDORG)
    const IFLIKAR = props.IFLIKAR
    const [IDORGSOTR_EDIT,setIDORGSOTR_EDIT] = React.useState(0)

    const [isOpen, setIsOpen] = React.useState(true)


    const [addWindow, setAddWindow] = React.useState(false);
    const [editWindow, setEditWindow] = React.useState(false);

    const addEmpl = async (p) =>{
        setAddWindow(false)

        //let param = {IDORG:IDORG,IDORGSOTR:IDORGSOTR,IDPROMOM:p}
        //await execPromo(param)

    }

    const loadData = async ()=>{     
        let d = await Eng001.loadPharmacyEmpl({IDORG:IDORG})
        if(d) {
            for(let i=0;i<d.length;i++){
                d[i]['TPZARK'] = '-'
                if(d[i]['IDSOTR']>1 && d[i]['IDSOTR2']>1) d[i]['TPZARK'] = 'Роб'
                if(d[i]['IDSOTR']==1 && d[i]['IDSOTR2']>1) d[i]['TPZARK'] = 'Заг'
                if(d[i]['MP_IFWORK']!='Y' && d[i]['MP']){d[i]['MP']='(не прац) '+d[i]['MP']}

            }
            return  d 
        }
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)

    const toggle = async () => setIsOpen(!isOpen)    
    let sotrH=['ПІБ','Посада','Лояльн','Перс.тел','ДН' ]
    let sotrF=['NAME1','DOLGN','LL','TELPERS','BIRTHDAY' ]
    if(IFLIKAR){
        sotrH=['ПІБ','Спец-ть','МП','ТипЗакр','ABC','Лояльн','Перс.тел','ДН' ]
        sotrF=['NAME1','VRACHSPEC','MP','TPZARK','ABC','LL','TELPERS','BIRTHDAY' ]
    
    } 
    const testEditLicarRights = ()=>{
        if(IFLIKAR){
            if(!appData.canEditDoctor() ){
                alert('Відсутні права на редагування лікарів...')
                return false
            }
        }

        return true
    }
    const toggleAdd = () => {
        if(!testEditLicarRights()) return
        setAddWindow(!addWindow)
    }
    const toggleEdit = () => setEditWindow(!editWindow)
    const closeAddEditWindow = () =>{
        if(addWindow)  setAddWindow(!addWindow)
        if(editWindow) setEditWindow(!editWindow)
        fLoadData()
    }
    const startEditSotr = (ID)=>{
        if(!testEditLicarRights()) return
        setIDORGSOTR_EDIT( ID )
        setEditWindow(true)
    }

    if(!IDORG) return <></>
    return <>
    
        {loading && <Wait1 />}
        {err && <Error1 />}
        {data && <div>
                    <Button  outline onClick={toggle} >Спіробітники</Button>
                    <Collapse isOpen={isOpen}>
                        <Card>
                            <CardHeader>
                                {IDORG && <Button  outline style={{float: 'right'}} onClick={toggleAdd}>{Emo.Add()}</Button>}
                            </CardHeader>

                            <CardBody>
                                <Table1 headers = {sotrH} initData= {data} fields = {sotrF} 
                                    tableLineButton ={Emo.Edit1()} 
                                    tableLineButtonFun={startEditSotr}
                                    tableLineButtonFunKeyFld={'IDORGSOTR'}                                
                                
                                
                                />
                            </CardBody>
                        </Card>
                    </Collapse>
                </div>
        }
        <Modal isOpen={addWindow} toggle={toggleAdd}>
            <ModalHeader toggle={toggleAdd}>Додаємо...</ModalHeader>
            <ModalBody>
                <EmplEdit1 IDORG={IDORG} IDORGSOTR={0} FUN={closeAddEditWindow} IFLIKAR={IFLIKAR}/>
            </ModalBody>
        </Modal>
        <Modal isOpen={editWindow} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>Редагуємо...</ModalHeader>
            <ModalBody>
                <EmplEdit1 IDORG={IDORG} IDORGSOTR={IDORGSOTR_EDIT} FUN={closeAddEditWindow} IFLIKAR={IFLIKAR}/>
            </ModalBody>
        </Modal>


    </>
}

export default PharmacyEmpl