/*
Редагуємо або створюємо "просту адмін роботу"
IDTASKTP==1
*/

import React from "react";
import { useNavigate } from "react-router-dom";
import { Button,Form,Collapse,Card,CardBody,Table } from "reactstrap"

import { RoutPath, remoteDayWorkCash} from "../../WTConst";
import { usePageData1 } from "../../comcom/PageData1";
import { usePosition } from "../../comcom/geo1";
import Emo from "../../comcom/Emo";
import Error1 from "../../comcom/Error1";
import ShowGeoPosition from "../ShowGeoPosition";
import Eng001 from "../../engine/eng001";
import Wait1 from "../../comcom/Wait1";

import Input1 from "./../../comcom/Input1"
import Input0 from "./../../comcom/Input0"
import { cloneObj } from "../../comcom/ComFunc"
import DoctorInfo1 from "../Doctor/DoctorInfo1";
import PharmacyInfo1 from "../Pharmacy/PharmasyInfo1";


const WorkTP1 = (props) =>{
    
    let  P = props.PARAM
    const [NewVal,setNewVal] = React.useState({})
    const [cliInfo,setCliInfo] = React.useState(false)
    const toggleCliInfo = () => setCliInfo(!cliInfo)
    const [isOpenHelp,setOpenHelp] = React.useState(false)
    const toggleOpenHelp = () => setOpenHelp(!isOpenHelp)
    const [ifShowBlockOc,setShowBlockOc] = React.useState(false)
    const toggleShowBlockOc = () => setShowBlockOc(!ifShowBlockOc)

    //let NewVal = {}

    //P : {DD: "2024-03-06" IDSOTR: 2081 IDTASK: 0 IDTASKTP: 1 SOTR : "МП (для тестування)" TP: "ADDTOWORK"}

    const navigate = useNavigate()
    const { latitude, longitude, wait, error } = usePosition();
    
    const goToWorkPage = () => {  navigate(RoutPath.Work1)  } 

    const loadData = async () =>{

        if(!P.IDWORK){
            return {IDTASK:0,IDSOTR:P.IDSOTR,IDTASKTP:P.IDTASKTP,T1:'',T5:''}
        }else{
            //log('call Eng001.loadTask',{IDWORK:P.IDWORK})
            const r = await Eng001.loadTask({IDWORK:P.IDWORK})
            P.SOTR = r.NAME1
            P.IDSOTR = r.IDSOTR
            P.DD = r.DTPLAN

            return r;
        }
        
        return {}
    }
    const [data,loading,err,fLoadData,setNewData,setloading] =  usePageData1(loadData)
    //const saveWork = async (p) => {
    //    let param = p.formData

    const VidpV = ["","Завжди","Майже завжди","Часто","Інколи","Дуже рідко","Ніколи"]
    const VidVVal = (v) => {
        if(v == "Завжди") return 5
        if(v == "Майже завжди") return 4
        if(v == "Часто") return 3
        if(v == "Інколи") return 2
        if(v == "Дуже рідко") return 1
        if(v == "Ніколи") return 0
        return -1
    }
    const ifPV =()=>{
        return data['IDTASKTP'] == 2 || data['IDTASKTP'] == 8
    }


    const saveWork = async () => {
        let param = NewVal
        param.DD = P.DD
        param.IDSOTR = P.IDSOTR
        param.latitude = latitude
        param.longitude = longitude
        param.IDTASK = P.IDWORK
        param.IDTASKTP = P.IDTASKTP //1 ?????
        const r = await Eng001.saveRMWork(param)
        if(r){
            setNewVal({})
            remoteDayWorkCash()
            goToWorkPage()

        }
    }


    const onChange = event =>{
        //alert(event.target.name+"="+ event.target.value)
        //NewVal[event.target.name] = event.target.value
        let itog = 0, kolV = 0

        let newValTMP = cloneObj(NewVal)
        if(ifPV()){
            //alert(28)
            let newData = cloneObj(data)
            for(let i=0;i<newData['TASKD'].length;i++){
                let nnv = 'RAT_S_'+newData['TASKD'][i]['IDTASKASK']
                if(event.target.name == nnv){
                    newData['TASKD'][i]['RAT_S'] = event.target.value  
                }
                let vi = VidVVal(newData['TASKD'][i]['RAT_S'])
                if(vi>-1){
                    kolV++
                    itog += vi
                }
            }
            if(kolV>0){
                itog = Math.round( itog / kolV )  
            }else{
                itog = 0
            }
            newData['RATING'] = itog
            setNewData(newData)

            
            newValTMP['RATING'] = itog
            //setNewVal( newVal2 )
    
        }
        //if(event.target.name) setNewVal({...NewVal,[event.target.name]:event.target.value})
        if(event.target.name) newValTMP[event.target.name] = event.target.value
        setNewVal( newValTMP )
    }  


    if(!P){
        return <>
        <Button color="primary" outline onClick={goToWorkPage}>{Emo.Cancel1()} Назад</Button>
        <Error1 msg={'Помилка в параметрах ...'} />
        </>
    }
    if(loading){
        return <>
        <Button color="primary" outline onClick={goToWorkPage}>{Emo.Cancel1()} Назад</Button>
        <Wait1 />
        </>        
    }

    let TData = data['TASKD']
    //let phh1 = (data['IDTASKTP']=='2' || data['IDTASKTP']=='8')
    //let RARING_EDIT = {fname:'RATING',ftype :'select',fcaption:'ЗАГАЛЬНА ОЦІНКА',fhint:'',fnotNull:true,fval:data.RATING,fonChange:onChange,fvals:['',0,1,2,3,4,5,6,7,8]}
    let RARING_EDIT = {fname:'RATING',ftype :'text',fcaption:'ЗАГАЛЬНА ОЦІНКА',fhint:'',fnotNull:true,fval:data.RATING,fonChange:onChange,fvals:['',0,1,2,3,4,5,6,7,8]}
    if(data['IDTASKTP'] == 2 || data['IDTASKTP'] == 8){   
       RARING_EDIT = {fname:'RATING',ftype :'info',fcaption:'ЗАГАЛЬНА ОЦІНКА',fhint:'',fnotNull:true,fval:data.RATING,fonChange:onChange,fvals:['',0,1,2,3,4,5,6,7,8]}
    }

    const fields = [
        {fname:'T1',ftype :'textarea',fcaption:'Опис роботи',fhint:'',fnotNull:true,fval:data.T1,fonChange:onChange},
        {fname:'T5',ftype :'textarea',fcaption:'Докладніше/Результат',fhint:'',fnotNull:true,fval:data.T5,fonChange:onChange},
    ] 
    if(P.IDTASKTP==7){
        let madrlist = []
        if(Array.isArray(data['MADRLIST']))data['MADRLIST'].map((m)=>madrlist.push(m['MADR']))
        fields.push(
            {fname:'MADR',ftype :'text',fcaption:'Aдреса',fhint:'',fnotNull:true,fval:data.MADR,fonChange:onChange,
            datalist:madrlist},    
        )
        TData = false
    }




    const getEditElement = (vv,N) =>{
        
        let tp=0
        let dd = ''
        if(N==1) {tp = vv['NNEDIT1']; dd = ''}
        else {tp = vv['NNEDIT2'];dd = '_'}

        let p ={fname:'TTT_'+vv['ID'],ftype:'info',fcaption:'',fhint:'',fval:'',fonChange:onChange,fvals:[]}
        if(tp==1){
            p.fname = 'RAT_N_'+dd+vv['IDTASKASK']
            p.ftype = "select";
            p.fvals=['',1,2,3,4,5,6,7,8]
            p.fval = vv['RAT_N']
        }
        if(tp==2){
            //p.fname = 'RAT_PT_'+vv['IDTASKASK']
            p.fname = 'RAT_S_'+dd+vv['IDTASKASK']
            p.ftype = "select";
            p.fvals= ["","+","-"]
            //p.fval = vv['RAT_PT']
            p.fval = vv['RAT_S']
        }
        if(tp==3){
            p.fname = 'RAT_S_'+dd+vv['IDTASKASK']
            p.ftype = "select";
            p.fvals=["","так","ні"]
            p.fval = vv['RAT_S']
        }
        if(tp==4){
            p.fname = 'COMMENT_'+dd+vv['IDTASKASK']
            p.ftype = "text"; 
            p.fval = vv['COMMENT']
        } 
        if(tp==5){
            p.ftype = "info";
        }

        if(tp==333){
            p.ftype = "text";
            if(N==1){
                p.fname = 'V1_'+vv['IDTASKASK']
                p.fval = vv['V1']
            }else{
                p.fname = 'V2_'+vv['IDTASKASK']
                p.fval = vv['V2']
            }
        }                
        if(tp==334){
            p.ftype = "info"
            if(N==1) p.fval = 'Призначення'
            else p.fval = 'Цінність'
        }
        if(tp==1000){
            p.fname = 'RAT_S_'+dd+vv['IDTASKASK']
            p.ftype = "select";
            p.fvals = VidpV
            p.fval = vv['RAT_S']
        }

        return <Input0 {...p} key={p.fname} />
        //return <Input name={name} type="text" defaultValue={v} onChange={onChange}/>

    }

    let strHelp = ''
    if(Array.isArray( TData )){
        for(let i=0;i<TData.length;i++){
            if(TData[i]['COMM'] != ''){
                if(strHelp) strHelp += '<br />'
                strHelp += '<b>'+TData[i]['ASK'] + '</b><br />'+TData[i]['COMM'] 
           }
        }
    }

    let vr = (data.IDORGSOTR>0)
    return <>

    <Button color="primary" outline onClick={goToWorkPage}>{Emo.Cancel1()} Назад</Button>
    <h5>{data.TASKTP}, {P.SOTR}</h5>
    {data.MPNAME1 && <>МП : {data.MPNAME1}</>}
    {data.VR && <><br />Лікар: {data.VR} {data.VRACHSPEC}</>}
    {data.ORGNAME && <><br />{data.ORGNAME} {data.ADR}</>}
    <br /> <Button  color="secondary" size="sm" outline onClick={toggleCliInfo}>Докладніше про клієнта</Button>
    {cliInfo && <> 
        <hr/>
        {!vr && <><br /> <PharmacyInfo1 IDORG={data.IDORG} /></>}
        {vr && <><br />  <DoctorInfo1 IDORGSOTR={data.IDORGSOTR} RefreshButton={''}/></>}
  
    </>
    }
    {data.NET && <><span style={{textDecoration:'underline',fontWeight:'bold'}}>Мережа: {data.NET}</span></>}
    <Form  onSubmit={event => {event.preventDefault()}}>
    {fields.map( (inpI,i) => <Input1 {...inpI} key={i} />)}

    <Button  outline  color="primary" onClick={saveWork} >Зберегти (оцінки потім)</Button>
    <Button  outline  color="primary" onClick={toggleShowBlockOc} >Оцінювати...</Button>
 {ifShowBlockOc && <>
    <hr />
    <h4>Оцінювання:</h4>
    <br />
    {strHelp && <>
        <Button  outline onClick={toggleOpenHelp} >Підказка...</Button>
        <Collapse isOpen={isOpenHelp}>
                        <Card>
                            <CardBody>
                                <div dangerouslySetInnerHTML={{ __html: strHelp }} />
                            </CardBody>
                        </Card>
                    </Collapse>    
    
    </>}
    <hr />    
    {TData && <table>
        { ifPV() && <tr style={{borderBottom:"1px solid"}}><td><b>Навик</b></td><td><b>оцінка МП</b></td></tr>}
        {TData.map((v,i)=>{
            return <tr style={{borderBottom:"1px solid"}}><td><b>{v['ASK']}</b></td><td><b>{getEditElement(v,1)}</b></td></tr>
        })}
        <tr><td><b>ЗАГАЛЬНА ОЦІНКА:</b></td><td><Input0 {...RARING_EDIT} key="RATING" /></td></tr>
    </table>}


    <Button color="primary" outline onClick={saveWork}>Зберегти</Button>
</>}
    </Form>

    {//<Form1 formCFG={formCFG}  />
    }


    

    <ShowGeoPosition latitude={latitude} longitude ={longitude} wait={wait} error={error} />

    </>
}

export default WorkTP1