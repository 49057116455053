import React from "react";
import { useLocation,useNavigate } from "react-router-dom";
import {Button} from "reactstrap";


import appData from "../AppData";

import { CachingTag,remoteDayWorkCash } from "../WTConst";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";

import RedirectToLogin from '../components/RedirectToLogin'
import Table1AndFilter from "../comcom/Table1AndFilter";

import Eng001 from "../engine/eng001";
import { usePageData1 } from "../comcom/PageData1";
import Emo from "../comcom/Emo";
import Wait1 from "../comcom/Wait1";
import Form1 from "../comcom/Form1";
//import UploadAndDisplayImage from "../comcom/UploadAndDissplayImage";
import LandingPage from "../comcom/LandingPage";
import FileUpload from "../components/FileUpload";
import NewsPrev from "../components/MainForm/NewsPrev";


const NewsEdit = props =>{
    const [ID70DOC,setID70DOC] = React.useState(props.ID70DOC)
    const [TP,SetTP] = React.useState('')
    const SaveNews = async (p) =>{
        p = p.formData
        p.ID70DOC = ID70DOC
        let ID70DOC_ = await Eng001.saveNews(p)
        setID70DOC(ID70DOC_)
        let dd = await LoadData(ID70DOC_)
        setNewData(dd)
    }
    const LoadData = async (ID=0) =>{
        if(ID===true) ID=0
        if((ID70DOC < 0 || props.ID70DOC <1) && (ID==0) ){
            let d = await Eng001.loadNews({ID70DOC : -1}) //props.ID70DOC})
            let tp_ = []
            for(let i=0;i<d["TP"].length;i++){
                tp_.push(d["TP"][i]["DOCTP"])
            }
            SetTP(tp_)

            return {
                ID70DOC,
                TXT1 :'',
                DFROM :'',
                DTO :'',
                IFIMAGE :'N',
                IFWORK:'Y'
            }
        } else { 
            let id_ = ID ? ID:props.ID70DOC
            let d = await Eng001.loadNews({ID70DOC : id_}) //props.ID70DOC})
            let tp_ = []
            for(let i=0;i<d["TP"].length;i++){
                tp_.push(d["TP"][i]["DOCTP"])
            }
            SetTP(tp_)
            return d["NEWS"][0]
        }
    }

    const [data,loading,err,fLoadData,setNewData] =  usePageData1(LoadData)

    if(!data) return <>
        <Button   outline on onClick={props.CancelFunc}>{Emo.Back1()}Назад</Button>  
        <Wait1 />
    </>

    const fields = [
        {fname:'TXT1',ftype :'textarea',fcaption:'Опис',fhint:'',fnotNull:true,fval:data.TXT1},        
        {fname:'DOCTP',ftype :'select',fcaption:'ТИП',fhint:'',fnotNull:true,fval:data.DOCTP,fvals : TP},
        {fname:'DFROM',ftype :'date',fcaption:'ДІЄ З ',fhint:'',fnotNull:true,fval:data.DFROM},
        {fname:'DTO',ftype :'date',fcaption:'ДІЄ ДО ',fhint:'',fnotNull:true,fval:data.DTO},
        {fname:'SHOWTOP',ftype :'select',fcaption:'НА ГОЛОВНИЙ ЕКРАН',fhint:'',fnotNull:true,fval:data.SHOWTOP,fvals:['','N','Y']},        
        {fname:'IFIMAGE',ftype :'select',fcaption:'БУДЕ МАЛЮНОК',fhint:'',fnotNull:false,fval:data.IFIMAGE,fvals : [' ','Y','N']},
        {fname:'IFWORK',ftype :'select',fcaption:'РОБОЧА',fhint:'',fnotNull:false,fval:data.IFWORK,fvals : ['Y','N']},
        {fname:'FFF',ftype :'info',fcaption:'ФАЙЛ:',fhint:'',fnotNull:false,fval:data.FILENAME,},
        {fname:'ID70DOC',ftype :'info',fcaption:'##',fhint:'',fnotNull:true,fval:data.ID70DOC},        
//        {fname:'FIMAGE',ftype :'image',fcaption:'Зображення',fhint:'',fnotNull:false,fval:''},
    ] 
    const formCFG = {
        caption : props.ID70DOC<0?"Створюємо <<новину>>":"Редагуємо ##"+props.ID70DOC,
        buttonCaption : "Зберегти",
        cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : SaveNews,
        actionCancel : props.CancelFunc,
        clearData : true,
        localStorageTag : '',
       // grid : 2,
    }     
    
    let IDFILE = false
    if(data){ IDFILE = data.IDFILEW}
    return <>
        <Button   outline on onClick={props.CancelFunc}>{Emo.Back1()}Назад</Button>  
        {loading && <Wait1 />}
        {!loading && <Form1  formCFG={formCFG}  />}
        {IDFILE && <><br />
                <FileUpload IDFILE={IDFILE}/>
                </>
        }
        <hr />
        <h4>Новина буде виглядати так:</h4>
        <Button   outline on onClick={fLoadData}>{Emo.Refresh1}Оновити</Button>  
        <NewsPrev d={data}/>
       
    </>
}
// <UploadAndDisplayImage IDFILE = {'1111'}  FILEMANE2 = {'AAAA'} />
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////




const  PageNewsADM = props =>{
    const [currRowInTable,setCurrRowInTable] = React.useState(0)
    const [ID70DOC,setID70DOC] = React.useState(0)
    const loadData = async(p) =>{
        try{
            if(p===true) p = {}
            let r = await Eng001.loadNews(p)
            return r["NEWS"]
        }catch(err){
            return false
        }
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)

    const createNewNews = ()=>{ if(ID70DOC==0) setID70DOC(-1000)  }
    const cancelEdit = ()=>{ setID70DOC(0)  }

    const ActionInTable = (row,fn,rowNN) => {
    }     

    const fieldsFilterForm = [
        {fname:'TXT1',ftype :'text',fcaption:'Опис',fhint:'',fnotNull:false,fval:''},        
        {fname:'DOCTP',ftype :'text',fcaption:'ТИП',fhint:'',fnotNull:false,fval:''},
        {fname:'DNEWS',ftype :'date',fcaption:'ДІЄ НА',fhint:'',fnotNull:false,fval:''},
    ] 

    const Theaders = ['Опис','Тип','З','До','Працює','Зображення']
    const Tfields = ['TXT1','DOCTP','DFROM','DTO','IFWORK','FILENAME']


    if(!appData.loginOk()) return (
        <RedirectToLogin />
    )    

    if(loading) return <Wait1 />
    const editNews = (p) => {
        setID70DOC(p)
    }
    if(ID70DOC){
        return <NewsEdit ID70DOC={ID70DOC} CancelFunc={cancelEdit}/>
    }
    return <>
    
        <Button color="link"  outline on onClick={createNewNews}><h4>{Emo.Add()}Створити</h4></Button>  
        <Table1AndFilter 
            formHeader={'Пошук'} 
            fieldsFilterForm = {fieldsFilterForm}
            loadDataFromServer = {fLoadData}
            Theaders = {Theaders} 
            Tfields = {Tfields} 
            fieldsForAction={"TXT"}
            actionInTable={ActionInTable} 
            filterOpt="1" 
            currRowInTable={currRowInTable}    
             setCurrRowInTable={setCurrRowInTable}           
            data = {data}
            loading = {loading}
            err ={err}
            tableLineButton = {Emo.Edit1()}            
            tableLineButtonFun = {editNews}
            tableLineButtonFunKeyFld={'ID70DOC'}            
        />            

    </>
}




export default PageNewsADM