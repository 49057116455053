import React from "react"
import { Button,Collapse,Card,CardBody } from "reactstrap"

import Wait1 from "../../comcom/Wait1"
import Error1 from "../../comcom/Error1"
import { usePageData1 } from "../../comcom/PageData1"
import { toInteger,dateTimeStrToStr3 } from "../../comcom/ComFunc"
import Eng001 from "../../engine/eng001"
import { Line1 } from "../../comcom/Line1"
import { LineIn1 } from "../../comcom/LineIn1"
import Table1 from "../../comcom/Table1";
import { NetShemottshnic } from "./NetShemotehnic"
import { ZvitMeregi } from "./ZvitMeregi"
import PromoCli from "../PromoMat/PromoCli"
import PharmacyEmpl from "./PharmacyEmpl"
import Comment1 from "../Com/Comment1"
import Styles1 from "../../comcom/Styles1"
import Pharm3plus1 from "./Pharm3plus1"
import { PharmAdrProd } from "./PharmAdrProd"


const PharmacyInfo1 = (props) =>{
    const IDORG = toInteger(props.IDORG)
    const [isOpenVV, setIsOpenVV] = React.useState(false)  
    const [isOpenEmpl, setIsOpenEmpl] = React.useState(false)  

    const loadData = async ()=>{
        if(IDORG){
            return await Eng001.getPharmacy2({IDORG:IDORG})
        }else{
            return false
        }
    }
    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)


    if(!IDORG){
        return <>    
        Error parameter....
    </>

    }
    if(loading || !data) return  <Wait1 />
    if(err) return <Error1 />
    
    if(!Array.isArray(data) || data.length===0){
        return <>    
        Error parameter....
    </>
    }
    const toggleVV = () => {setIsOpenVV(!isOpenVV)}
    const toggleEmpl = () => {setIsOpenEmpl(!isOpenEmpl)}
    const vizits = data[0]['VIZITS']
    const vizitsH=['Дата','МП','ПРАЦЮЄ?' ]
    const vizitsF=['DTVIZIT','NAME1_MP','IFWORK_MP' ]   
    
    //<Button color="primary" outline onClick={fLoadData}> Оновити</Button>
    let IFLIKAR = (data[0]['IDORGTP'] == 7)
    return <>
        <br /><span style={Styles1.styleBold_U()}>{data[0]['ORGNAME']}</span>
        <br /><span style={Styles1.styleBold()}>{data[0]['ADR']}</span>
        <br />
            <LineIn1 p={'Регіон'} v={data[0]['DISTRICT']}  />
            <LineIn1  v={data[0]['OBLPART']}  />
            <LineIn1 p={'Обл'} v={data[0]['OBL']}  />
            <LineIn1 p={'Р-н'} v={data[0]['RN']}  />
        <br />
        {!isOpenEmpl && <Button  outline on onClick={toggleEmpl}>Спіробітники...</Button>}    
        {isOpenEmpl && <PharmacyEmpl IDORG={IDORG} IFLIKAR={IFLIKAR} />}

        <Line1 v={data[0]['MP']} p={'МП'}  />
        <br />
            <LineIn1 v={data[0]['TPZAKR']} p={'Тип.закр.'} />
            <LineIn1 v={data[0]['ABC']} p={'ABC:'} />
            <LineIn1 v={data[0]['LOYALTY']} p={'Лояльн:'} />
        <br />
            <LineIn1 v={data[0]['KOL_VIZIT_PREV_MONTH']} p={'Візитів: попер./міс'}  />
            <LineIn1 v={data[0]['KOL_VIZIT_CURRENT_MONTH']} p={'Візитів: поточн/міс'}  />
        {data[0]['DTLASTVIZIT'] && <>
                <br /><Button  outline onClick={toggleVV} >Візити (ост:{dateTimeStrToStr3(data[0]['DTLASTVIZIT'])}) </Button>
                    <Collapse isOpen={isOpenVV}>
                        <Card>
                            <CardBody>
                                <Table1 headers = {vizitsH} initData= {vizits} fields = {vizitsF} />
                            </CardBody>
                        </Card>
                    </Collapse>     
        </>}           

        
        
          
        <br />
            <LineIn1 p={'Закупки по місяцям:позаминулий'} v={data[0]['PROD3']}   />
            <LineIn1 p={'минулий'} v={data[0]['PROD2']}   />
            <LineIn1 p={'поточний'} v={data[0]['PROD1']}   />
        <Line1 v={data[0]['SUM_MONTH']} p={'Продажі "по моріону.міс"'}  />

        
        <Line1 v={data[0]['NET_TXT']+"/"+data[0]['KAM']} p={'Мережа'}  />
        <br />
        <NetShemottshnic IDNCONTR={data[0]['IDNCONTR']}/>
        <br />
        <ZvitMeregi IDORG={data[0]['IDORG']}/>
        <br />
        <PharmAdrProd IDORG={data[0]['IDORG']}/>
        
        <PromoCli IDORGSOTR={0} IDORG={IDORG} />
        <br />
        <Pharm3plus1  IDORG={data[0]['IDORG']}/>
        <br />
        <Comment1 TXT={data[0]['COMMENT']} TABLE={'TT'} ID={IDORG} />
        
        <hr />
    </>
}
//IDORG = {IDORG}
export default PharmacyInfo1