import Eng000 from "./engine/eng000";
import {removeAllCashData,localStorageName,VERSION} from "./WTConst"


class AppData {
    static #onlyInstance = null; //Singleton
    constructor(){
        if(!AppData.#onlyInstance){
            this.data = {version : VERSION}
            this.IDS = 0
            this.loadData();    
            this.IDS = this.data.IDS
            AppData.#onlyInstance = this;
        } else {
            return AppData.#onlyInstance;
        }
    }

    login = async (_login,_pwd) => {
        removeAllCashData()
        const rez = await Eng000.login(_login,_pwd,VERSION);
        this.setAppData(rez);
        this.saveData()
        return rez
    }
    setAppData = (data)=>{
        if(data.userName) {
            this.data = data
            this.data.version = VERSION
        }else{
            this.data = {}
        }
    }
    loginOk = () =>{
        return !!this.data.userName
    }
    getVersion = () =>{
        return this.data.version
    }    
    getToken = () =>{
        return this.data.token 
    }
    IFMP = () => {
        return this.data.ifmp
    }
    testRights(r){
        return this.data.rights.indexOf(r) > -1
    }
    canEditDoctor(){
        //IDRIGHT	RNAME1
        //1	ADMINISTRATOR SYSTEM 
        //170	Редаг. лікарів
        //171	Редаг. своїх лікарів
        //172	Адмін ред.лікарів        
        return  this.testRights(1) || this.testRights(170) ||  this.testRights(172)
    }

    saveData = () =>{
        localStorage.setItem(localStorageName,JSON.stringify(this.data))
    }
    loadData = () => {
        const rez = localStorage.getItem(localStorageName)
        if(rez){
            try{
                this.data = JSON.parse(rez)
                this.IDS = this.data.IDS
                if(!this.data.mnu) this.data.mnu = []
            }catch{
                this.data = {}
            }
        }
    }
    logOut = async () =>{
        this.data = {version : VERSION}
        localStorage.removeItem(localStorageName)
    }
    IDSOTR = ()=>{
        return this.data.idsotr
    }
}
const appData = new AppData(); //Singleton

export default appData