import React from "react";
import { useNavigate } from "react-router-dom";
import { Button,Collapse,
    Card,CardBody,CardHeader,CardTitle,CardText
} from "reactstrap";




import { CachingTag } from "../WTConst";
import usePageDataCashedWT1 from "../components/PageDataCashedWT1";
import Form1 from "../comcom/Form1";
import Emo from "../comcom/Emo";
import { dateStrWEB,cloneObj } from "../comcom/ComFunc";
import Eng001 from "../engine/eng001";
import Wait1 from "../comcom/Wait1";
import { RoutPath } from "../WTConst";
import UsgPrev from "../components/MainForm/UsgPrev";
import appData from "../AppData";

const PageWtDocList = props =>{
    const navigate = useNavigate()
    const [fform,showFform] = React.useState(false)
    const toogleFForm = () => showFform(!fform)
   
    const [fFilter,setFfilter] = React.useState({DFROM : dateStrWEB(new Date(),-2)})
    const [showCreateNew,setShowCreateNew] = React.useState(false)
    const [newDOCTYPE,setNewDOCTYPE] = React.useState(false)


    const loadData = async (p) => {
        try{
            if(p) setFfilter(p)
            else p = fFilter
            if(!p.DFROM) {
                p.DFROM = dateStrWEB(new Date(),-1)
                setFfilter(p)
            }
            let d = await Eng001.loadDocList(p)
            return d
        }catch(e){
   
            return false;
        }
    }
    const funcTestData = () => { return true}
    
    let [data,loading,err,funLoadData,setNewData] = usePageDataCashedWT1(CachingTag.Uzgodg1,loadData,funcTestData)
    //React.useEffect(()=>{ funLoadData() },[])
    
    const createNewDoc = async (p) => {
        let DOCTP = p.formData.TYPE_NEW
        let DOCTXT1 = p.formData.DOCTXT1_NEW
        if(!DOCTP || !DOCTXT1){
            alert('Заповніть тип та опис!')
            return
        }
        let IDDOC = await  Eng001.createDoc({DOCTP,DOCTXT1})
        setShowCreateNew(false)      
        navigate(RoutPath.WTDOC,{state : {"IDDOC":IDDOC}})        
        //alert(IDDOC)
    }

    const setFilter = ( p ) => {
        if(p && p.formData){
            p = p.formData
        }else{
            p = {DFROM : dateStrWEB(new Date(),-5)} //-1
        }
        setFfilter(p)
        showFform(false)
        funLoadData(p)
        
    }

    const delDoRozgladu = (IDDOC) =>{
        let data_tmp = cloneObj(data)
        for(let i=0;i<data.length;i++){
            if(data_tmp[i]['IDDOC'] == IDDOC) data_tmp[i]['ALARM'] = ''
        }
        setNewData(data_tmp)
    }

    const fieldsNewDoc = [
        {fname:'TYPE_NEW',ftype :'select',fcaption:'Тип',fhint:'',fnotNull:false,fval:'',fvals: newDOCTYPE},
        {fname:'DOCTXT1_NEW',ftype :'text',fcaption:'Опис',fhint:'',fnotNull:false,fval:''},
    ]             

    const formCFGNewDoc = {
        caption : "",
        buttonCaption : "Створити",
        cancelBtnCaption : 'Відміна',
        fields : fieldsNewDoc,
        action : createNewDoc,
        actionCancel :  () => setShowCreateNew(false),
        clearData : true,
        localStorageTag : undefined,
    }

    const createNew = async ()=>{
        if(!newDOCTYPE){
            let tp = await Eng001.loadTPNewDoc()
            let tpArr = []
            for(let i=0;i<tp.length;i++){
                tpArr.push(tp[i]['DOCTP'])
            }
            setNewDOCTYPE( tpArr)
        }
        if(showCreateNew) {
            setShowCreateNew(false)
            return
        }
        if(appData.IDSOTR() != 99){
            alert('в розробці.....')
            return
        }
        showFform(false)
        setShowCreateNew(true)
    }


    const fields = [
        {fname:'DFROM',ftype :'date',fcaption:'Дата з',fhint:'',fnotNull:false,fval:fFilter.DFROM},
        {fname:'DTO',ftype :'date',fcaption:'До',fhint:'',fnotNull:false,fval:''},
        {fname:'TYPE',ftype :'text',fcaption:'Тип',fhint:'',fnotNull:false,fval:''},
        {fname:'USER1',ftype :'text',fcaption:'Ініціатор',fhint:'',fnotNull:false,fval:''},
        {fname:'IDDOC',ftype :'number',fcaption:'Номер',fhint:'',fnotNull:false,fval:''},
        //{fname:'TXT',ftype :'text',fcaption:'Текст',fhint:'',fnotNull:false,fval:''},
        ]
    const formCFG = {
        caption : "",
        buttonCaption : "Пошук",
        cancelBtnCaption : 'Відміна',
        fields : fields,
        action : setFilter,
        actionCancel : toogleFForm,
        clearData : false,
        localStorageTag : CachingTag.Uzgodg1_F,
        }
   
     
        return <>
        <h5>Узгодження</h5>
                <Button color="link"  outline onClick={toogleFForm}>{Emo.Look1()}{'Пошук'}</Button>
                <Button color="link"  outline onClick={createNew}>{Emo.Add()}{'Створити'}</Button>  
                <Button color="link" style={{float:'right'}} outline onClick={setFilter}>{Emo.Cicle2()}{'Останні'}</Button>  
                <Collapse isOpen={showCreateNew}>
                <Card>
                        <CardBody>
                            <Form1 formCFG={formCFGNewDoc}  />    
                        </CardBody>
                </Card>

                </Collapse>
                <Collapse isOpen={fform}>
                    <Card>
                        <CardBody>
                            <Form1 formCFG={formCFG}  />                
                        </CardBody>
                    </Card>
                </Collapse>
                {loading && <Wait1 />}
                {data && <>
                    <hr />Вибрано : {data.length} узгоджень
                    {data.map((d,i)=>
                        <UsgPrev d = {d} delDoRozgladu={delDoRozgladu}/>
                    )}
                    </>
                }
                </>
}

export default PageWtDocList