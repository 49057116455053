import React from "react";
import {  useLocation,useNavigate } from "react-router-dom";
import { Button,Card,CardHeader,CardBody,CardTitle,CardText 
,Collapse } from "reactstrap";
import { usePageData1 } from "../comcom/PageData1";
import Wait1 from "../comcom/Wait1";
import { dateTimeStrToStr1,txtToArray } from "../comcom/ComFunc";
import { SelectUser } from "../components/Com/SelectUser";
import { cloneObj } from "../comcom/ComFunc";

import Emo from "../comcom/Emo";
import Eng001 from "../engine/eng001";
import Form1 from "../comcom/Form1";

const PageWTDoc = (props) =>{
    const navigate = useNavigate()
    const location = useLocation()
    const [isEdit, setIsEdit] = React.useState(false);
    const toggleEdit = () => setIsEdit(!isEdit);

    const [isNewStatus, setNewStatus] = React.useState(false);
    const toggleNewStatus = () => setNewStatus(!isNewStatus);


    let IDDOC = 0
    if(location.state && location.state.IDDOC) IDDOC = location.state.IDDOC
    const goBack=()=>{navigate(-1)}

    const loadData = async () => {
        if(IDDOC){
            return await Eng001.loadDoc({IDDOC})
        }
        return false
    }
    const addUserToList = (newUser)=>{
        if(newUser){            
            if(newUser['ADD']){
               let newUser_ = {ID:newUser['ID'],IDSOTR:newUser['IDSOTR'],IDDOC,IDDOCSTATUS:newUser['ALARMSTATUS'],NAME1:newUser['NAME1']}
               let newData = cloneObj(data)
               newData.u.push(newUser_)
               setNewData(newData)
            }
        }
    }
    const addUserTo = async (ID) =>{
        const param = {
            IDDOC,IDSOTR_TO_ADD:ID,ALARMSTATUS : -1000
        }
        let r= await Eng001.addUserToDoc(param)
        addUserToList(r)
    }
    const addUserCopy = async(ID)=>{
        const param = {
            IDDOC,IDSOTR_TO_ADD:ID,ALARMSTATUS : 0
        }
        let r= await Eng001.addUserToDoc(param)
        addUserToList(r)
    }
    const deleteUser = async(ID)=>{
        const param = {
            IDDOC,IDSOTR_TO_DELETE:ID
        }
        let r= await Eng001.deleteUserFromDoc(param)
        alert('Оновіть документ...')
    }


    const [data,loading,err,fLoadData,setNewData] =  usePageData1(loadData)

    if(!IDDOC) return <>
        <Button color="link"  outline onClick={goBack}>{Emo.Back1()}{'Назад'}</Button>  
        <br />відсутній IDDOC... 
        </>


    if(loading ) return  <Wait1 />
    if(err) return <>Error... </>
    if(!data ) return  <>
        <Button color="link"  outline onClick={goBack}>{Emo.Back1()}{'Назад'}</Button>  
        <Wait1 />
        </>

    let r1 = data.r1 //шапка документу
    let r2 = data.r2 //тіло документу
    let r3 = data.r3 //можливі статуси 
    let u = data.u      
  

    let userList1 = '',userList2 = ''
    let UserL1=[],UserL2=[]
    let IDUSERLIST = ','
    for(let i=0; i<u.length; i++){
        IDUSERLIST += u[i]['IDSOTR']+','
        if(u[i]['IDDOCSTATUS'] == r1['IDDOCSTATUS']){
            if(userList1 != '') userList1 = userList1 + ','  
            userList1 = userList1 + u[i]['NAME1']
            UserL1.push(u[i]['IDSOTR'])
        }else{
            if(userList2 != '') userList2 = userList2 + ','  
            userList2 = userList2 + u[i]['NAME1']
            UserL2.push(u[i]['IDSOTR'])
        }
        if(userList1 !='' && userList2 !='') userList1 = userList1+','
    }

    const AddTXT = async(p)=>{
        if(p.formData.NEW_TXT){
            let p1 = {IDDOC:IDDOC,TXT:p.formData.NEW_TXT}
            let r = await Eng001.AddTXTToDoc(p1)
            if(r){
                setIsEdit(false)
                await fLoadData()
            }
        }
        setIsEdit(false)
    }
    const setStatus = async(p)=>{
        let IDDOCSTATUS = 0 
        for(let i=0;i<r3.length;i++){
            if(r3[i]['DOCSTATUS'] == p.formData['NEW_STATUS']){
                IDDOCSTATUS = r3[i]['IDDOCSTATUS'] 
            }
        }
        if(IDDOCSTATUS == r1['DOCSTATUS']) IDDOCSTATUS = 0
        if(IDDOCSTATUS > 0){
            let param = {IDDOC,IDDOCSTATUS_NEW:IDDOCSTATUS,IDDOCSTATUS_OLD:data.r1['IDDOCSTATUS']}
            let r = await Eng001.setNewSatusToDoc(param)
            if(r)  await fLoadData()
        }
    
        setNewStatus(false)
    }

    let varStatus = []
    for(let i=0;i<r3.length;i++){
        varStatus.push(r3[i]['DOCSTATUS'])
    }
    const fieldsStatus = [ {fname:'NEW_STATUS',ftype :'select',fval:r1.DOCSTATUS,fvals:varStatus},]
    const formCFGStat = {
        caption : "Новий статус:",
        buttonCaption : "Зберегти",
        cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fieldsStatus,
        action : setStatus,
        actionCancel : toggleNewStatus,
        clearData : true,
        localStorageTag : '',
    }     




    const fields = [ {fname:'NEW_TXT',ftype :'textarea',fval:''},]
    const formCFG = {
        caption : "",
        buttonCaption : "Зберегти",
        cancelBtnCaption : 'Відміна',
        formMsg : '',   
        formErrorMsg : '',
        fields : fields,
        action : AddTXT,
        actionCancel : toggleEdit,
        clearData : true,
        localStorageTag : '',
    }     

    
    return <>
        <Button color="link"  outline onClick={goBack}>{Emo.Back1()}{'Назад'}</Button>  
        <Button color="link"  outline onClick={fLoadData}>{Emo.Refresh1()}{'Оновити'}</Button>  
        {r1 && r1.IDDOC && <>
            <span style={{float:'right'}}>#{r1.IDDOC} </span>
            <br />Тип : <b>{r1.DOCTP}</b>
            <hr />Опис:<b> {r1.DOCTXT1}</b>
            <br />Кому:<b><u>{userList1} </u>{userList2}</b>
            <br />
            <SelectUser CAPT="+КОМУ" FUN={addUserTo} CAPTH="(кому)"/>
            <SelectUser CAPT="+КОПІЯ" FUN={addUserCopy}  CAPTH="(копія)"/>
            <SelectUser CAPT="-ВИДАЛИТИ" FUN={deleteUser} CAPTH="(видаляємо)" IDFILTER={IDUSERLIST}/>
            <br />  
            <Button  size="sm" outline onClick={toggleNewStatus}>Статус</Button>{': '}<b>{r1.DOCSTATUS} </b>
            <Collapse isOpen={isNewStatus} >
                    <Card>
                    <CardBody>
                        <Form1 formCFG={formCFGStat} />
                    </CardBody>
                    </Card>
                </Collapse>                
                    
                    
            <br />  Створив : <b>{r1.NAME1SCREATE} {' '} {dateTimeStrToStr1( r1.DTCREATE )}</b>
            <br />  Редагував: <b>{r1.LASTEDIT.substr(0,15)} {' '} {dateTimeStrToStr1( r1.DTMODI )}</b>


            {r2 && <>
                <hr /><Button color="primary"  outline onClick={toggleEdit}>{Emo.Edit1()}{'Написати'}</Button>  
                <Collapse isOpen={isEdit} >
                    <Card>
                    <CardBody>
                        <Form1 formCFG={formCFG} />
                    </CardBody>
                    </Card>
                </Collapse>                
                {r2.map((txt,i)=>{
                    let txtAr = txtToArray(txt.TXT)
                    
                    return(
                    
                <Card  >
                <CardHeader>
                    <b>{txt.NAME1S}{' '}{dateTimeStrToStr1(txt.DTMODI)}</b><span style={{float:'right'}}>#{txt.IDDOCP} </span>
                </CardHeader>
                <CardBody>
                <CardText>
                    {txtAr.map((t)=><div style={{whiteSpace: 'pre-wrap'}}>{t}</div>)}
                    {//<div dangerouslySetInnerHTML={txt.TXT} />;
}
                </CardText>
                </CardBody>
                </Card>
                )})}
                </>

            }
            </>}
        </>

}

export default PageWTDoc
